@import '_colors.scss';

.settings-sidebar {
    .menu-list li {
        margin-bottom: 20px;
    }

    .menu-list {
        padding-left: 20px;
    }
}

.personal-img-container {
    width: 80px;
    height: 80px;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    background-color: lightgrey;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0px;
    font-weight: bold;
    padding-left: 14px;
    padding-top: 5px;
}

.bar {
    height: 1px;
    background-color: #d0d0d0;
}
