@import '_colors.scss';

.login-form {
    margin-top: 120px;
    max-width: 400px;
    width: 400px;
    display: flex;
    flex-direction: column;
}

.login-page {
    display: flex;
    align-items: center;
    justify-content: center;
}
