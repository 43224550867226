@import '_colors.scss';

.provider-card-top {
    .provider-img {
        min-width: 60px;
        width: 60px;
        max-width: 60px;
        min-height: 60px;
        height: 60px;
        max-height: 60px;
        background-size: cover;
        background-position: center;
        border-radius: 50%;
        background-color: #f5f5f5;
        cursor: pointer;

        &.small {
            min-width: 48px;
            width: 48px;
            max-width: 48px;
            min-height: 48px;
            height: 48px;
            max-height: 48px;
        }

        &.margin-top-small {
            margin-top: 6px;
        }
    }

    .provider-card-name {
    }

    display: flex;
    align-items: center;
}

.appointment-photo {
    min-width: 200px;
    width: 200px;
    max-width: 200px;
    max-height: 267px;
    height: 267px;
    min-height: 267px;
    background-size: cover;
    background-position: center;
    background-color: #f5f5f5;
    object-fit: cover;
}

.tip-button {
    border: 1px solid $brand-accent;
    border-radius: 5px;
    display: inline-block;
    padding: 6px 12px;
    color: $brand-accent;
    background-color: white;

    &.selected {
        color: white;
        background-color: $brand-accent;
    }

    &.custom {
        max-width: 100px;
    }

    &:hover {
        color: white;
        background-color: $brand-accent;
    }
}
