@import '_colors.scss';

.bar-button-selector {
    width: 100%;

    .bar {
        height: 1px;
        background-color: #d0d0d0;
    }

    .bar-button {
        cursor: pointer;
        padding: 10px 20px;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box; /* Firefox, other Gecko */
        box-sizing: border-box;
        font-weight: normal;
        border-bottom: 2px rgba(0, 0, 0, 0) solid;

        &.small {
            font-size: 14px;
            padding: 4px 10px;
        }

        &:hover {
            border-bottom-color: $brand-main;
            color: $brand-main;
            text-shadow: 0px 0px 1px $brand-main;
        }

        &.selected {
            border-bottom-color: $brand-main;
            color: $brand-main;
            text-shadow: 0px 0px 1px $brand-main;

            &.is-info {
                border-bottom-color: $brand-accent;
                color: $brand-accent;
                text-shadow: 0px 0px 1px $brand-accent;
            }
        }
    }
}
