@import '_colors.scss';

#profile-page {
    background-color: #f2f5f5;
    min-height: 100vh;
    height: auto;
}

.profile-sidebar-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    svg {
        margin-right: 15px;
        stroke: #a9a9a9;
    }

    p {
        font-size: 1rem;
        font-weight: normal;
        color: #727272;
    }

    &.selected p {
        font-weight: 500;
        color: #515151;
    }

    &.selected svg {
        stroke: #777777;
    }

    &:hover p {
        font-weight: 500;
        color: #515151;
    }

    &:hover svg {
        stroke: #777777;
    }
}

.profile-container {
    padding-top: 2rem;
}

.profile-sidebar {
    .menu-list li {
        margin-bottom: 10px;
    }

    .menu-list {
        padding-left: 20px;
    }
}

.profile-header {
    position: relative;
}

.profile-corner-btns {
    position: absolute;
    right: -1rem;
    top: -1rem;
}

.profile-basic-info {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profile-name {
    width: 90%;
}

.profile-bio {
    width: 90%;
}

.profile-exp-rating {
    width: 80%;
}

.profile-experience-item {
    position: relative;
    display: flex;
    align-items: flex-start;

    .grey {
        color: $grey-text;
    }

    .profile-experience-img {
        flex-shrink: 0;
    }
}

.profile-avail-item {
    position: relative;
}

.is-48x48 {
    width: 48px;
}

.edit-icon {
    position: absolute;
    right: 0;
    top: 0;
}

.profile-img-container {
    min-width: 100px;
    width: 100px;
    max-width: 100px;
    max-height: 100px;
    height: 100px;
    min-height: 100px;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    background-color: #f5f5f5;
    border: 2px solid #cacaca;
}

.rounded-img {
    min-width: 100px;
    width: 100px;
    max-width: 100px;
    max-height: 100px;
    height: 100px;
    min-height: 100px;
    object-fit: cover;
    border-radius: 50%;
    background-color: #f5f5f5;
    border: 1px solid #cacaca;
}

.profile-img {
    max-width: 128px;
    max-height: 128px;
    width: auto;
    height: auto;
    object-fit: cover;
}

.profile-section {
    min-height: calc(100vh - 315px);
}

.todo-list-container {
    background-color: white;
    padding: 20px;
}

.profile-selector-img {
    max-height: 150px;
}

.todo-item {
    // min-height: 20px;
    border-radius: 5px;
    &:hover {
        background-color: rgba(38, 194, 150, 0.06);
    }
    label {
        word-break: break-word;
    }

    h5 {
        width: 100%;
        word-wrap: break-word;
    }

    overflow-y: hidden;
}

.todo-item-text {
    width: 90%;
}

.item-completed {
    text-decoration: line-through;
    color: lightgray;
}

.attachment {
    background: #fafafa;
    border: 1px dashed #e8e8e8;
    box-sizing: border-box;
    border-radius: 4px;
    color: $brand-main;
    padding-top: 5px;
    padding-bottom: 5px;
}

.skills {
    display: flex;
    flex-direction: row;
}

.profile-skill-item {
    p {
        padding-right: 5px;
    }
    background-color: grey;
    padding: 4px 5px 4px 10px;
    background: #f1f1f1;
    border-radius: 3px;
    font-size: 14px;
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.photo-gallery {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .arch-dropzone {
        width: 200px;
        margin-right: 20px;
        margin-bottom: 20px;
        padding-left: 0px;
        padding-right: 0px;
        height: 200px;
    }

    .arch-dropzone-video {
        width: 33%;
        height: auto;
        padding-right: 10px;
        border: 3px dashed #a7a7a7;
        box-sizing: border-box;
        border-radius: 4px;
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .photo-img-container {
        background-size: cover;
        background-position: center;
        border-radius: 10px;
        background-color: lightgrey;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        margin-right: 20px;
        margin-bottom: 20px;
        position: relative;
    }

    .photo {
    }
}

.gallery-item {
    width: 33%;
    height: auto;
    padding-right: 10px;
}

.photo-item {
    border-radius: 5px;

    video {
        border-radius: 10px;
    }
}

.photo-img-container {
    width: 200px;
    height: 200px;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    background-color: lightgrey;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-right: 20px;
    margin-bottom: 20px;
    position: relative;

    .icon {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}

.profile-video-container {
    position: relative;

    .icon {
        position: absolute;
        top: 20px;
        right: 10px;
    }
}

.employee-card {
    background: #ffffff;
    border: 1px solid #dde0e3;
    box-sizing: border-box;
    border-radius: 4px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;

    &:hover {
        border-color: $brand-main;
        cursor: pointer;

        h6 {
            color: $brand-main;
        }
    }

    .employee-card-img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: #f2f2f2;
        overflow: hidden;
    }
}

.profile-card {
    position: relative;

    .card-button {
        position: absolute;
        top: 30px;
        right: 30px;
    }
}

.connection-img-container {
    min-width: 50px;
    min-height: 50px;
    height: 50px;
    width: 50px;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    background-color: #f5f5f5;
}

.connection-item {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    border-bottom: 1px #dfdfdf solid;

    .connection-name {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .connection-info {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .connection-actions {
        display: flex;
        align-items: center;
    }

    &.last {
        border-bottom: 0px #dfdfdf solid;
    }
}

.avail-select-card {
    position: fixed;
    bottom: 0;
    z-index: 100;
}

.time-picker-slot {
    display: flex;
    align-items: center;
}

.rbc-event {
    border: 2px solid $brand-accent !important;
    background-color: white !important;
    color: $brand-accent !important;
    cursor: default !important;
}

.rbc-month-view {
    z-index: 0 !important;
}

.awards-image {
    min-width: 50px;
    width: 50px;
    max-width: 50px;
    max-height: 50px;
    height: 50px;
    min-height: 50px;
    margin-right: 1rem;
    margin-right: 2rem;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    background-color: #c4c4c4;
    cursor: pointer;
}

.media-image {
    min-width: 200px;
    width: 200px;
    max-width: 200px;
    max-height: 200px;
    height: 200px;
    min-height: 200px;
    background-size: cover;
    background-position: center;
    border-radius: 5%;
    background-color: #ececec;
    cursor: pointer;
}

.react-calendar {
    border: none !important;
    .react-calendar__navigation {
        margin-bottom: 0px !important;
    }

    .react-calendar__tile--now {
        background-color: #6c63ff33;
    }

    .react-calendar__month-view__days__day--weekend {
        color: inherit;
    }

    .react-calendar__tile--active {
        background: $brand-accent !important;
        color: white;
    }
}
