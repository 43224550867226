@import './colors.scss';

.arch-footer {
    background-color: rgb(0, 0, 0) !important;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    color: white;
    flex: 1 0 auto;
    display: flex;

    &.light {
        background-color: #ffffff !important;
        color: black;
    }

    .logo {
        height: 2rem;
        width: auto;
    }

    a {
        color: white;
        &:hover {
            color: whitesmoke;
        }
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    .arch-footer {
        background-color: rgb(0, 0, 0) !important;
    }
}
