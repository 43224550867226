@import './_colors.scss';

.register-page {
    background-color: $grey-background;
    min-height: 100vh;
    height: auto;

    .steps {
        padding-top: 2rem;
    }

    .registration-card {
        margin-top: 3rem;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 200px;
    }
}

.bottom-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

// .registration-card {
//     padding-top: 2rem;
// }

.profile-selection {
    margin-top: 200px;
}

.profile-type-card {
    border-radius: 5px;
    &:hover {
        border: solid 5px $brand-main;

        h3 {
            color: $brand-main;
        }
    }
}

.remove-experience {
    color: $brand-danger;
    &:hover {
        color: $brand-danger;
    }
}

.control .icon {
    pointer-events: initial !important;
}

.clinic-item {
    .dropdown-menu {
        pointer-events: all;
        height: 0px;
        overflow-y: scroll;
        width: 95%;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding-top: 0px;
    }

    .dropdown.is-active .dropdown-menu {
        display: block;
        height: 200px;
        box-shadow: 0px 10px 12px 7px rgba(0, 0, 0, 0.05);
    }

    .dropdown {
        display: block;
        pointer-events: all;
    }

    .control {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    input {
        width: 95%;
    }

    .clinic-time-slot {
        display: flex;
        align-items: center;
    }
    .dropdown-content {
        padding-top: 0px;
    }
}

.type-button {
    width: 48%;
    text-align: left;
    justify-content: flex-start;
    padding-top: 10px;
    padding-bottom: 10px;
    height: auto;
}
